<template>
  <div>
    {{ duration }}
  </div>
</template>
<script>
import {diffUtc} from "@/utils/timezone";
import {getDuration, MINUTES} from "@/utils/date";

export default {
  props: ['startAt', 'endAt'],
  computed: {
    duration () {
      const durations = diffUtc(this.endAt, this.startAt, MINUTES);

      return getDuration(durations);
    }
  }
}
</script>
